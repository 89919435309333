.document_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    background-size: contain;
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 35vw;
  padding: 0px 20px 2rem;
  margin-top: -1rem;
}
.pdf {
  width: 65vw;
  padding-bottom: 2rem;
  margin-right: 1rem;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .document_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150%;
  }

  .list {
    width: 60%;
    height: 60vh;
    margin: 0 auto 2rem;
  }
  .list h1 {
    margin: 1rem auto;
  }
  .pdf {
    width: 80%;
    margin: 0 auto;
  }
  html,
  body {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .list {
    width: 80%;
  }
  .pdf {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .list {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .list {
    width: 100%;
    padding: 0px;
  }

  .list h1 {
    margin: 1rem auto;
    font-size: 24px;
  }
  .pdf {
    width: 100%;
  }
}
